.not-found-page{
    margin-top: 120px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    font-weight: 500;
}
.not-found-description{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
@media(max-width:768px){
    .not-found-page{
        font-size:x-large;
    font-weight: 500;
    }
}