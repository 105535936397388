.item {
    width: 22%;
    justify-content: center;
    border: 1px solid #c09f66;
    border-radius: 16px;
    padding: 5px;
    box-shadow: 1px 2px 3px  #c09f66;
    margin-bottom: 2px;
  }
  .item:hover {
    border: 1px solid #cc9f51;
  }
  .item img {
    width: 1000px;
    /* height: 1000px; */
    height: 270px;
    display: flex;
    flex-wrap: wrap;
    justify-items: center;
    border: 1px solid transparent;
    border-radius: 16px;
  }
  .item p {
    margin: 6px 8px;
  }
  .item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    word-wrap: break-word;
  }
  .item-rating {
    margin: 0px 8px;
  }
  .item-prices {
    display: flex;
    gap: 15px;
    margin: 10px 8px;
  }
  .item-price-new {
    color: #374151;
    font-size: 18px;
    font-weight: 600;
  }
  .item-price-old {
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
  }
  .item:hover {
    transform: scale(1.05);
    transition: 0.6s;
  }
  
  @media (max-width: 800px) {
    .item {
      width: 45%;
    }
    .item-price-old {
      font-size: 12px;
    }
  }
  @media (max-width: 500px) {
    .item p {
      font-size: 14px;
    }
    .item-price-new {
      font-size: 14px;
    }
    .item img {
      height: 180px;
    }
  }
  @media (min-width: 998px) {
    .item img {
      height: 270px;
    }
  }
  @media (max-width: 768px) {
    .item-prices {
      display: flex;
      align-items: center;
      margin: 10px 8px;
    }
  }
  