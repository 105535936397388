.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner p {
  font-size: 1.5rem;
  color: #ff5722; /* Match your theme color */
}
