.footer-social-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.footer-icons-containers {
    padding: 8px;
    background: #e1c89b;
    border: 1px solid #ebebeb;
    border-radius: 8px;
}

.footer-icons-containers a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-icons-containers img {
    width: 25px;
}

@media (max-width: 768px) {
    .footer-social-icons {
        gap: 8px;
        flex-wrap: wrap;
    }

    .footer-icons-containers {
        padding: 6px;
    }

    .footer-icons-containers img {
        width: 22px;
    }

    .footer-columns {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-columns div {
        margin-bottom: 15px;
    }
}

@media (max-width: 500px) {
    .footer-social-icons {
        flex-wrap: wrap;
        gap: 5px;
    }

    .footer-icons-containers {
        padding: 5px;
        border-radius: 6px;
    }

    .footer-icons-containers img {
        width: 20px;
    }

    .footer-columns div {
        margin-bottom: 10px;
    }

    .footer-policy, .footer-menu, .footer-contact {
        text-align: center;
        padding: 5px;
    }

    .footer-policy ul, .footer-menu ul, .footer-contact ul {
        text-align: center;
        padding: 0;
    }
}
