.refund-policy-container {
    max-width: 900px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }

  .title {
    text-align: center;
    font-size: 2.5rem;
    margin-top: 100px;
    margin-bottom: 20px;
    color: #333;
  }


  .section {
    margin-bottom: 20px;
  }

  .section-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    line-height: 1.6;
  }

  /* a {
    color: #007bff;
    text-decoration: underline;
  } */

  @media (max-width: 768px) {
    .refund-policy-container {
      padding: 10px;
    }
    .title {
      font-size: 1.5rem;
    }
    .section-title {
      font-size: 1.25rem;
    }
  }
