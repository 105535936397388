
.query-page-main .query-title{
  color: #171717;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  text-align: center;
}
.query-page-main hr {
  width: 100px;
  height: 4px;
  border-radius: 10px;
  background: #252525;
  margin: 0 auto;
  margin-top: 10px;
  /* display: flex;
  justify-content: center; */
}

 .query-section-title{
  font-size: 30px;
  font-weight: 600;
}
.query-description{
  margin:40px auto;
}

.query-page-main{
    margin-bottom: 40px;
    margin-top: 40px;
}
.query-text{
    font-size: 20px;
    margin: 10px auto;
}
.listed-text ul {
    list-style: none;
  }
  .listed-text  li:before {
    content: '✓';
    font-size: 20px;
  }
  .listed-text ul li{
    display:flex ;
    align-items: center;
    gap: 20px;
    margin: 15px 0px;
    font-size: 17px;
    font-family: sans-serif;
  }

  .rightside-card{
    display: flex;
    gap: 20px;
  }
  .rightside-icon{
    display: flex;
    height: 100%;
    margin-top: 10px;
  }
  .rightside-card div .card-description{
    font-size: 16px;
  }
  .rightside-secoend-icon{
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  @media(max-width:768px){
    .query-page-main .query-title{
      font-size: 30px;
    }
    .query-section-title{
        font-size: 24px;
    }
    .rightside-icon{
        margin-top: 5px;
    }
    .rightside-card-title{
        font-size: 18px !important;
    }
  }
  @media(max-width:375px){
    .query-section-title{
        font-size: 30px !important;
    }
  }