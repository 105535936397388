/* BlogPage.css */
.blog-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    background-color: #f5f7fa;
}

.blog-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
    margin: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    text-align: center;
}

.blog-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.15);
}

.blog-image-wrapper {
    overflow: hidden;
}

.blog-image {
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
    transition: transform 0.3s ease;
}

.blog-card:hover .blog-image {
    transform: scale(1.1);
}

.blog-content {
    padding: 20px;
}

.blog-title {
    font-size: 1.6rem;
    color: #333;
    margin-bottom: 10px;
}

.blog-author {
    color: #777;
    margin-bottom: 10px;
}

.blog-description {
    color: #555;
    margin-bottom: 15px;
}

.link-box {
    display: flex;
    gap: 10px; /* Add some space between the buttons */
    justify-content: space-around; /* Center align the buttons */
}

.blog-read-more,
.blog-product-link {
    display: inline-block;
    background-color: #007bff;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 150px; 
}

.blog-read-more:hover,
.blog-product-link:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}


@media (min-width: 768px) {
    .blog-card-box {
        width: 30%;
    }
}
