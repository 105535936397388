/* PrivacyPolicy.css */

.privacy-policy-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }

  .title {
    text-align: center;
    font-size: 2.5rem;
    margin-top: 100px;
    margin-bottom: 20px;
    color: #333;
  }

  .intro-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin-bottom: 30px;
  }

  .section {
    margin-bottom: 30px;
  }

  .section h2 {
    font-size: 1.5rem;
    color: #333;
    border-bottom: 2px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
    color: #555;
  }

  ul li {
    margin-bottom: 10px;
    font-size: 1rem;
  }

  ul li strong {
    color: #333;
  }

  .error-message {
    color: red;
    font-weight: bold;
  }

  /* a {
    color: #007bff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  } */
