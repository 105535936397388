
.search-title {
  margin-top: 100px;
}

.shopcategory-products{
  /* width: 90%; */
  /* max-width: 100%; */
  justify-content: center;
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;

}

@media(min-width:768px) {
  .shopcategory-products {
    width: 90%;
    max-width: 100%;
    gap: 20px;
  }
}