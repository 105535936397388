/* BlogDetail.css */
.blog-detail-container {
    max-width: 800px;
    margin: 0px auto 40px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.blog-detail-image-wrapper {
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.blog-detail-image {
    object-fit: cover;
    transition: transform 0.3s ease;
}

.blog-detail-image-wrapper:hover .blog-detail-image {
    transform: scale(1.1);
}

.blog-detail-title {
    font-size: 2.2rem;
    color: #333;
    text-align: center;
}

.blog-detail-author {
    color: #777;
    text-align: center;
    margin-bottom: 20px;
}

.blog-detail-content {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #555;
    text-align: justify;
}

.blog-product-link {
    display: inline-block;
    background-color: #007bff; /* Primary button color */
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    color: #ffffff; /* White text color */
    text-align: center;
    text-decoration: none; /* Remove underline */
    margin-top: 10px;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition */
}

.blog-product-link:hover {
    background-color: #0056b3; /* Darker shade for hover */
    transform: translateY(-2px); /* Slightly lift the button on hover */
}
