.whatsapp-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.whatsapp-button {
  background-color: #25d366;
  border: none;
  border-radius: 50%;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.whatsapp-button:hover {
  transform: scale(1.1);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
}

.whatsapp-icon {
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
}

.whatsapp-button:hover .whatsapp-icon {
  transform: rotate(10deg);
}

.tooltip {
  margin-top: 8px;
  font-size: 0.8rem;
  color: #666;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s ease-in-out;
  white-space: nowrap;
}

.whatsapp-button-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Mobile view adjustments */
@media screen and (max-width: 768px) {
  .whatsapp-button-container {
    right: 10px;
    bottom: 15px;
  }

  .whatsapp-button {
    padding: 12px;
  }

  .whatsapp-icon {
    width: 35px;
    height: 35px;
  }

  .tooltip {
    display: none;

  }
}
