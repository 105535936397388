.about-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 150px;
  color: #333;
  font-family: 'Arial', sans-serif;
}
.about hr {
  width: 100px;
  height: 4px;
  border-radius: 10px;
  background: #252525;
  margin: 0 auto;
}

.about-content {
  max-width: 500px;
  padding-right: 20px;
}

.about-title {
  font-size:30px;
  text-align: center;
  color: #222;
  font-weight: 600;
  margin-top: 24px;
}

.about-text {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 15px;
}

.about-signature {
  font-size: 18px;
  font-style: italic;
  margin-top: 30px;
}

.about-image img {
  max-width: 600px;
  width: 100%;
  height: auto; /* Ensures the image retains its aspect ratio */
  border-radius: 50%; /* Makes the image round */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  object-fit: cover; /* Ensures the image covers the entire area and doesn't distort */
}

/* Media Queries */
@media (max-width: 750px) {
  .about-container {
    flex-direction: column;
    padding: 30px;
  }

  .about-content {
    padding-right: 0;
    max-width: 100%;
    text-align: center;
  }

  .about-image {
    margin-top: 20px;
  }
}

@media (max-width: 500px) {
  .about-title {
    font-size: 28px;
  }

  .about-text {
    font-size: 16px;
  }

  .about-signature {
    font-size: 16px;
  }
}
