.navbar {
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  box-shadow: 0 1px 2px -2px black;
  padding: 15px 70px;
  top: 0;
  background-color: white;
  z-index: 1000;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.navbar-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.navbar {
  position: sticky; /* Keeps the navbar fixed at the top of the page */
  width: 100%; /* Make sure it takes the full width of the page */
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
@media (min-width: 768px) {
  .navbar {
    top: 40px;
  }
}

.navbar.hidden {
  transform: translateY(-100%); /* Moves the navbar out of view when hidden */
  opacity: 0; /* Optional: hide the navbar more smoothly */
}

@media (min-width: 768px) {
  .navbar.not-home {
    top: 0px;
  }
}

.navbar.visible {
  transform: translateY(0); /* Brings the navbar back into view */
  opacity: 1;
}

.navbar:hover {
  /* background-color: #f8f8f8; */
  /* box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.2); */
}

.nav-logo img {
  width: 110px;
  height: 55px;
}

/* small  nav menu items */
.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 40px;
  font-size: 16px;
  font-weight: 500;
}

.nav-menu li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  width: 100%;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.nav-menu li a {
  text-decoration: none;
  color: inherit;
}

.nav-menu li:hover {
  color: #ff4141;
}

.nav-menu hr {
  border: none;
  width: 80%;
  height: 3px;
  border-radius: 10px;
  background: #ff4141;
}

.nav-search-form {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
@media (min-width: 768px) {
  .nav-search-form {
    display: none;
  }
}

.nav-search-form-desktop {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  /* border: 1px solid #ccc; */
  padding: 2px;
}
@media (max-width: 768px) {
  .nav-search-form-desktop {
    display: none;
  }
}

.nav-search-input {
  padding: 10px 8px;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
}

@media (min-width: 768px) {
  .nav-search-input {
    width: 200px;
  }
}

.nav-search-input:focus {
  outline: none;
  border-color: #96999d;
}

.nav-search-button {
  /* background-color: rebeccapurple; */
  cursor: pointer;
  border: 1px solid rgb(225, 214, 214);
  border-radius: 50%;
  color: rgb(105, 102, 102);
}

.nav-search-button:hover {
  background-color: rgb(113, 93, 42);
  color: white;
}
/* .nav-login-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
} */

/* login btn  */
.login-btn {
  width: 120px;
  height: 40px;
  outline: none;
  border: 1px solid #e1d6d6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 75px;
  color: #515151;
  font-size: 18px;
  font-weight: 500;
  background: white;
  cursor: pointer;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.login-btn:hover {
  background: linear-gradient(45deg, #76c329, #b1256b, #c0056c);
  color: white;
  border: 1px solid transparent;
}

.nav-login-cart img {
  width: 35px;
}

.nav-cart-count {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
  margin-left: -40px;
  font-size: 14px;
  background: red;
  color: white;
}

.nav-dropdown {
  display: none;
}

.profile-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 50%; /* Position the dropdown from the left */
  transform: translateX(-50%); /* Center the dropdown */
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.profile-dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content button {
  background: none;
  border: none;
  color: #ff4141;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px;
  text-align: left;
  width: 100%;
}

.dropdown-content button:hover {
  background-color: #f1f1f1;
}

@media (max-width: 750px) {
  .navbar {
    padding: 15px 20px;
  }
  .nav-login-cart {
    gap: 15px;
  }
  .nav-dropdown {
    display: block;
    width: 30px;
    rotate: -90deg;
    transition: 0.5s;
  }
  .nav-menu {
    display: none;
    height: 80px;
    width: 100%;
    position: absolute;
    background-color: white;
    justify-content: center;
    top: 60px;
    left: -20px;
  }
  .nav-menu-visible {
    display: flex;
    gap: 0px; /* when mobile screen the gap between navbar items*/
    font-size: 12px; /* it will good looking when menu bar is open*/
    font-weight: 700;
  }
  .nav-dropdown.open {
    transform: rotate(90deg);
  }
  .nav-cart-count {
    margin-left: -25px;
  }
  .navbar.home {
    top: 40px;
    position: sticky;
  }
  .not-home {
    top: 0px;
  }
}

@media (max-width: 500px) {
  .navbar {
    padding: 15px 10px;
    /* margin-top: 20px; */
  }
  .nav-logo img {
    width: 120px;
  }
  .nav-menu-visible {
    flex-wrap: wrap;
    height: 300px;
    top: 75px;
  }
  /* .nav-login-cart {
    transform: scale(0.8);
  }
  .nav-login-cart {
    gap: 10px;
  } */
  .navbar.visible.not-home {
    top: 0px;
    margin-top: 0;
  }
}
